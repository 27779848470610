let config = {
    s3: {
        REGION: "us-east-2",
        BUCKET: "forwood-vale-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-vale-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-east-2",
        URL: "https://5rxxqwcfs5el7kewamgtmsj4ty.appsync-api.us-east-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-east-2",
        URL: "https://9lnmqdqzm8.execute-api.us-east-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://mkpezi3wre.execute-api.us-east-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.vale.forwoodsafety.com",
        WEBSOCKET: "wss://jiap0cb7vj.execute-api.us-east-2.amazonaws.com/staging"
    },
    cognito: {
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-2_uNxxRpKGT",
        IDENTITY_POOL_ID: "us-east-2:2164ce47-7634-4a94-83b9-6453d1bcd02e",
        USERPOOL_HOSTED_DOMAIN: "forwood-vale-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.vale.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.vale.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::595878987322:role/staging-CA"
    },
    samlProvider: {
      NAME: 'Vale',
      SUPPORTED_LIST: 'Vale,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.3",
        HOSTNAME: "id.staging.vale.forwoodsafety.com",
        COOKIE_DOMAIN: ".staging.vale.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "45563fb2-5bf6-48e4-beae-ebb6547700b9",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.vale.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
